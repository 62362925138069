import React, { forwardRef } from 'react';

export const BackgroundInitSVG = forwardRef(function BackgroundInitSVGRef(
    _props,
    ref
) {
    return (
        <svg
            viewBox="0 200 1440 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMax slice"
            ref={ref}
        >
            <g>
                <path
                    d="M-536 302C-536 302 -74.0357 263 251.457 263C576.95 263 684.401 320 1118.91 320C1553.43 320 2075 302 2075 302"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-874 342C-874 342 -420.882 303 -101.623 303C217.637 303 323.031 360 749.223 360C1175.42 360 1687 342 1687 342"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-552 304C-552 304 -90.0357 265 235.457 265C560.95 265 668.401 322 1102.91 322C1537.43 322 2059 304 2059 304"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-890 344C-890 344 -436.882 305 -117.623 305C201.637 305 307.031 362 733.223 362C1159.42 362 1671 344 1671 344"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-489 300C-489 300 -27.0357 261 298.457 261C623.95 261 731.401 318 1165.91 318C1600.43 318 2122 300 2122 300"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-827 340C-827 340 -373.882 301 -54.6227 301C264.637 301 370.031 358 796.223 358C1222.42 358 1734 340 1734 340"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-489 298C-489 298 -27.0357 259 298.457 259C623.95 259 731.401 316 1165.91 316C1600.43 316 2122 298 2122 298"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-827 338C-827 338 -373.882 299 -54.6227 299C264.637 299 370.031 356 796.223 356C1222.42 356 1734 338 1734 338"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-474 295.684C-474 295.684 -11.8588 256 313.758 256C639.376 256 746.869 314 1181.55 314C1616.23 314 2138 295.684 2138 295.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-812 335.684C-812 335.684 -358.705 296 -39.3211 296C280.063 296 385.498 354 811.857 354C1238.22 354 1750 335.684 1750 335.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-442 292.684C-442 292.684 19.9643 253 345.457 253C670.95 253 778.401 311 1212.91 311C1647.43 311 2169 292.684 2169 292.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-780 332.684C-780 332.684 -326.882 293 -7.6227 293C311.637 293 417.031 351 843.223 351C1269.42 351 1781 332.684 1781 332.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-615 312C-615 312 -153.036 273 172.457 273C497.95 273 605.401 330 1039.91 330C1474.43 330 1996 312 1996 312"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-953 352C-953 352 -499.882 313 -180.623 313C138.637 313 244.031 370 670.223 370C1096.42 370 1608 352 1608 352"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-631 314C-631 314 -168.859 275 156.758 275C482.376 275 589.869 332 1024.55 332C1459.23 332 1981 314 1981 314"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-969 354C-969 354 -515.705 315 -196.321 315C123.063 315 228.498 372 654.857 372C1081.22 372 1593 354 1593 354"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-568 310C-568 310 -106.036 271 219.457 271C544.95 271 652.401 328 1086.91 328C1521.43 328 2043 310 2043 310"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-906 350C-906 350 -452.882 311 -133.623 311C185.637 311 291.031 368 717.223 368C1143.42 368 1655 350 1655 350"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-568 308C-568 308 -106.036 269 219.457 269C544.95 269 652.401 326 1086.91 326C1521.43 326 2043 308 2043 308"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-906 348C-906 348 -452.882 309 -133.623 309C185.637 309 291.031 366 717.223 366C1143.42 366 1655 348 1655 348"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-552 306C-552 306 -90.0357 267 235.457 267C560.95 267 668.401 324 1102.91 324C1537.43 324 2059 306 2059 306"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-890 346C-890 346 -436.882 307 -117.623 307C201.637 307 307.031 364 733.223 364C1159.42 364 1671 346 1671 346"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-783 326C-783 326 -321.036 287 4.45692 287C329.95 287 437.401 344 871.914 344C1306.43 344 1828 326 1828 326"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1121 366C-1121 366 -667.882 327 -348.623 327C-29.3632 327 76.031 384 502.223 384C928.415 384 1440 366 1440 366"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-799 328C-799 328 -337.036 289 -11.5431 289C313.95 289 421.401 346 855.914 346C1290.43 346 1812 328 1812 328"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1137 368C-1137 368 -683.882 329 -364.623 329C-45.3632 329 60.031 386 486.223 386C912.415 386 1424 368 1424 368"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-736 324C-736 324 -274.036 285 51.4569 285C376.95 285 484.401 342 918.914 342C1353.43 342 1875 324 1875 324"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1074 364C-1074 364 -620.882 325 -301.623 325C17.6368 325 123.031 382 549.223 382C975.415 382 1487 364 1487 364"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-736 322C-736 322 -274.036 283 51.4569 283C376.95 283 484.401 340 918.914 340C1353.43 340 1875 322 1875 322"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1074 362C-1074 362 -620.882 323 -301.623 323C17.6368 323 123.031 380 549.223 380C975.415 380 1487 362 1487 362"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-721 319.684C-721 319.684 -258.859 280 66.7585 280C392.376 280 499.869 338 934.548 338C1369.23 338 1891 319.684 1891 319.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1059 359.684C-1059 359.684 -605.705 320 -286.321 320C33.063 320 138.498 378 564.857 378C991.216 378 1503 359.684 1503 359.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-689 316.684C-689 316.684 -227.036 277 98.4569 277C423.95 277 531.401 335 965.914 335C1400.43 335 1922 316.684 1922 316.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1027 356.684C-1027 356.684 -573.882 317 -254.623 317C64.6368 317 170.031 375 596.223 375C1022.42 375 1534 356.684 1534 356.684"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-799 330C-799 330 -337.036 291 -11.5431 291C313.95 291 421.401 348 855.914 348C1290.43 348 1812 330 1812 330"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
                <path
                    d="M-1137 370C-1137 370 -683.882 331 -364.623 331C-45.3632 331 60.031 388 486.223 388C912.415 388 1424 370 1424 370"
                    stroke="#1934F5"
                    strokeOpacity="0.05"
                    strokeWidth="2"
                    shapeRendering="geometricPrecision"
                />
            </g>
        </svg>
    );
});
