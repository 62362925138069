import React from 'react';
import imgUrl from './michael-eden.png';

export const MichaelEdenPortrait = () => (
    <img
        src={imgUrl}
        style={{ borderRadius: '100%', height: 'auto', width: '100%' }}
        alt="Michael Eden"
    />
);
