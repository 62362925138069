import React from 'react';
import backgroundUrl from '../../images/benefits/demand-planning-bg.svg';
import { Box } from '../system';
import { BenefitVisual } from './BenefitVisual';
import { Visual } from './Visual';

export const DemandPlanningVisuals = () => {
    return (
        <Box
            position="relative"
            height="100%"
            minHeight="400px"
            backgroundImage={`url(${backgroundUrl})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Visual
                translate="20%"
                offset="10px"
                bottom="50%"
                right={{ xs: 0, md: '-60px' }}
                width={{
                    xs: '500px',
                    sm: '530px'
                }}
                maxWidth={{
                    xs: '100%',
                    md: '120%'
                }}
                style={{
                    left: 0
                }}
            >
                <BenefitVisual name="demand-planning-schedule" />
            </Visual>
            <Visual
                translate="95%"
                offset="-10px"
                width={{
                    xs: '340px',
                    sm: '410px'
                }}
                style={{
                    left: 140,
                    bottom: '40%'
                }}
            >
                <BenefitVisual name="demand-planning-overview" />
            </Visual>
        </Box>
    );
};
