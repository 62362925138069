import styled, { keyframes } from 'styled-components';
import { Box } from '../system';

const softBounce = (translateY = '0%', offset = '20px') => keyframes`
0% {
  transform: translateY(${translateY});
}

100% {
  transform: translateY(calc(${translateY} + ${offset}));
}
`;

export const Visual = styled(Box)`
    position: absolute;
    transform: translateY(${props => props.translate});
    animation: ${props => softBounce(props.translate, props.offset)} 8s
        alternate infinite;
    animation-timing-function: ease-in-out;
`;
