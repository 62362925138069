import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Box, Heading3 } from '../system';
import {
    CategoryManagementVisuals,
    DemandPlanningVisuals,
    SmartOrderingVisuals,
    LeftRightContent
} from './';
import { BenefitContent } from '../common';

export const Features = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col>
                    <Box textAlign="center" marginTop={4} marginBottom={16}>
                        <Heading3>{t('index.benefitsHeadline')}</Heading3>
                    </Box>
                    <Box>
                        <LeftRightContent
                            image={<CategoryManagementVisuals />}
                            textContent={
                                <BenefitContent
                                    headline={t('index.benefits.0.headline')}
                                    description={t(
                                        'index.benefits.0.description'
                                    )}
                                    bulletItems={t(
                                        'index.benefits.0.bulletItems',
                                        {
                                            returnObjects: true
                                        }
                                    )}
                                />
                            }
                            imagePosition="right"
                        />
                    </Box>

                    <Box marginTop={{ xs: 11, md: 17 }}>
                        <LeftRightContent
                            imagePosition="left"
                            image={<DemandPlanningVisuals />}
                            textContent={
                                <BenefitContent
                                    headline={t('index.benefits.1.headline')}
                                    description={t(
                                        'index.benefits.1.description'
                                    )}
                                    bulletItems={t(
                                        'index.benefits.1.bulletItems',
                                        {
                                            returnObjects: true
                                        }
                                    )}
                                />
                            }
                        />
                    </Box>
                    <Box marginTop={{ xs: 11, md: 18 }}>
                        <LeftRightContent
                            image={<SmartOrderingVisuals />}
                            textContent={
                                <BenefitContent
                                    headline={t('index.benefits.2.headline')}
                                    description={t(
                                        'index.benefits.2.description'
                                    )}
                                    bulletItems={t(
                                        'index.benefits.2.bulletItems',
                                        {
                                            returnObjects: true
                                        }
                                    )}
                                />
                            }
                            imagePosition="right"
                        />
                    </Box>
                </Col>
            </Row>
        </Container>
    );
};
