import React, { createRef, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box } from '../system';
import { BackgroundInitSVG } from './BackgroundAnimationInitSVG.js';

const random = (min, max) => Math.random() * (max - min + 1) + min;
const duration = (n = 2) => n + 's';
const movement = offset => keyframes`
  from {
    transform: translateY(-${offset}px);
  }

  to {
    transform: translateY(${offset}px);
  }
`;

const inset = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

const Container = styled(Box)`
    ${inset}
    z-index: -1;

    svg {
        ${inset}
        width: 100%;
        height: 75%;
        object-fit: contain;

        @media screen and (min-width: 768px) {
            height: 100%;
        }

        path {
            animation: ${movement(20)} ${duration()} ease-in-out infinite
                alternate;
            transform-origin: center;
        }
    }
`;

/**
 * @param {SVGPathElement} path
 * @param {Number} i
 */
const addDelay = (path, i) => {
    path.style.animationDelay = i * 50 + 'ms';
    path.style.animationDuration = duration(random(1, 4) * random(1, 4));
};

export const BackgroundAnimation = () => {
    const ref = createRef();

    useEffect(() => {
        if (ref.current) {
            ref.current.querySelectorAll('path').forEach(addDelay);
        }
    }, [ref]);

    return (
        <Container color="bold-blueish.2">
            <BackgroundInitSVG ref={ref} />
        </Container>
    );
};
