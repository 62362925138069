import { Link } from 'gatsby';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ROUTES from '../../routes';
import { ContentHeader } from '../common';
import { Box, Button, BUTTON_MODIFIERS } from '../system';

export const Header = () => {
    const { t } = useTranslation();

    return (
        <Box textAlign="center" alignItems="center">
            <Box marginBottom={8}>
                <ContentHeader
                    headline={t('index.header.headline')}
                    subHeadline={<Trans i18nKey="index.header.subHeadline" />}
                />
            </Box>

            <Button
                modifiers={[BUTTON_MODIFIERS.PRIMARY]}
                as={Link}
                display="inline-block"
                to={ROUTES.CONTACT}
            >
                Schedule a demo
            </Button>
        </Box>
    );
};
