import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-grid-system';

import { SpacedCol } from '../common';
import { Default, Mobile } from '../common/ScreenSizes';

const DesktopView = props => {
    if (props.imagePosition === 'left') {
        return (
            <Row>
                <SpacedCol md={6} sm={12}>
                    {props.image}
                </SpacedCol>

                <Col md={5} sm={12} offset={{ md: 1 }}>
                    {props.textContent}
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <SpacedCol md={5} sm={12}>
                {props.textContent}
            </SpacedCol>

            <Col md={6} sm={12} offset={{ md: 1 }}>
                {props.image}
            </Col>
        </Row>
    );
};

DesktopView.propTypes = {
    textContent: PropTypes.node,
    image: PropTypes.node,
    imagePosition: PropTypes.oneOf(['left', 'right'])
};

export const LeftRightContent = props => {
    return (
        <React.Fragment>
            <Default>
                <DesktopView
                    imagePosition={props.imagePosition}
                    image={props.image}
                    textContent={props.textContent}
                />
            </Default>

            <Mobile>
                <Row>
                    <SpacedCol sm={12}>{props.textContent}</SpacedCol>

                    <SpacedCol sm={12} offset={{ md: 1 }} marginTop={4}>
                        {props.image}
                    </SpacedCol>
                </Row>
            </Mobile>
        </React.Fragment>
    );
};

LeftRightContent.propTypes = {
    textContent: PropTypes.node,
    image: PropTypes.node,
    imagePosition: PropTypes.oneOf(['left', 'right'])
};
