import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ROUTES from '../../routes';
import { BodyText1, BodyText2 } from '../common';
import { Button, BUTTON_MODIFIERS, Flex } from '../system';
import { get } from '../system/get';

const QuoteSymbol = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-30%, -25%);
    font-size: 300px;
    opacity: 1;
    z-index: 1;
    opacity: 0.075;
    color: ${get('colors.cl-dark-blue.2')};
    pointer-events: none;
`;

export const Testimonial = ({ captionKey, textKey }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col lg={9} sm={12} position="relative">
                    <QuoteSymbol>{'“'}</QuoteSymbol>
                    <BodyText1 fontSize={[4, 6]} lineHeight="default">
                        <Trans i18nKey={textKey} />
                    </BodyText1>
                    <BodyText2 fontSize={2} marginTop={2}>
                        <Trans i18nKey={captionKey} />
                    </BodyText2>
                </Col>
                <Col>
                    <Flex
                        alignItems="center"
                        height="100%"
                        marginTop={{ xs: 3, lg: 0 }}
                    >
                        <Button
                            modifiers={[BUTTON_MODIFIERS.PRIMARY]}
                            as={Link}
                            to={ROUTES.CONTACT}
                            display="inline-block"
                        >
                            {t('index.featuredTestimonial.cta')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Container>
    );
};
Testimonial.propTypes = {
    captionKey: PropTypes.string,
    textKey: PropTypes.string
};
