import React from 'react';
import backgroundUrl from '../../images/benefits/smart-ordering-bg.svg';
import { Box } from '../system';
import { BenefitVisual } from './BenefitVisual';
import { Visual } from './Visual';

export const SmartOrderingVisuals = () => {
    return (
        <Box
            position="relative"
            height="100%"
            minHeight="400px"
            backgroundImage={`url(${backgroundUrl})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Visual
                bottom="50%"
                translate="30%"
                offset="-10px"
                width="540px"
                maxWidth={{
                    xs: '100%',
                    md: '120%'
                }}
                left={{ xs: 0, md: '-60px' }}
            >
                <BenefitVisual name="smart-ordering-offers" />
            </Visual>
            <Visual
                translate="80%"
                offset="10px"
                style={{
                    right: -20,
                    bottom: '70%',
                    width: '250px'
                }}
            >
                <BenefitVisual name="smart-ordering-chat" />
            </Visual>
        </Box>
    );
};
