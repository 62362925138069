import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

export const BenefitVisual = ({ name, className, style }) => {
    const data = useStaticQuery(
        graphql`
            {
                allFile(
                    filter: {
                        relativeDirectory: { eq: "benefits" }
                        extension: { eq: "png" }
                    }
                ) {
                    edges {
                        node {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            name
                        }
                    }
                }
            }
        `
    );

    const illustration = data.allFile.edges.find(
        edge => edge.node.name === name
    );

    return (
        <Img
            className={className}
            fluid={illustration.node.childImageSharp.fluid}
            imgStyle={style}
            alt=""
        />
    );
};

BenefitVisual.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string
};
