import React from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {useTranslation} from 'react-i18next';
import {
  CenteredTextWrapper,
  SpacedCol,
  StoryHighlight
} from '../common';
import {JanWolffPortrait, MichaelEdenPortrait} from '../home-page';
import {Box, Heading3} from '../system';

export const WhatOthersSay = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <CenteredTextWrapper>
                        <Heading3>What others are saying</Heading3>
                    </CenteredTextWrapper>
                </Col>
            </Row>
            <Box marginTop={{ xs: 8, md: 10 }}>
                <Row>
                    <Col xs={12} md={6}>
                        <StoryHighlight
                            profileImage={<JanWolffPortrait />}
                            quote={t('index.testimonials.0.quote')}
                            testimonialName={t('index.testimonials.0.name')}
                            testimonialJobTitle={t(
                                'index.testimonials.0.jobTitle'
                            )}
                        />
                    </Col>

                    <SpacedCol xs={12} md={6} marginTop={{ xs: 5, md: 0 }}>
                        <StoryHighlight
                            profileImage={<MichaelEdenPortrait />}
                            quote={t('index.testimonials.1.quote')}
                            testimonialName={t('index.testimonials.1.name')}
                            testimonialJobTitle={t(
                                'index.testimonials.1.jobTitle'
                            )}
                        />
                    </SpacedCol>
                </Row>
            </Box>
        </Container>
    );
};
