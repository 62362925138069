import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
    Box as SystemBox,
    Button,
    BUTTON_MODIFIERS,
    Heading5
} from '../system';
import { BodyText1 } from '../common';

const Box = styled(SystemBox)`
    height: 100%;
`;

export const CategoryBox = props => {
    return (
        <Box
            paddingY={7}
            paddingX={7}
            boxShadow="ultraSmall"
            backgroundColor="white"
        >
            <Heading5>{props.headline}</Heading5>

            <BodyText1 as="p" color="cl-dark-blue.1" fontSize={2} marginTop={4}>
                {props.description}
            </BodyText1>

            <Box marginTop={6}>
                <Button
                    as={Link}
                    to={props.href}
                    display="block"
                    modifiers={[BUTTON_MODIFIERS.PRIMARY]}
                    width="100%"
                >
                    {props.buttonText}
                </Button>
            </Box>
        </Box>
    );
};

CategoryBox.propTypes = {
    headline: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired
};
