import React from 'react';
import backgroundUrl from '../../images/benefits/category-management-bg.svg';
import { Box } from '../system';
import { BenefitVisual } from './BenefitVisual';
import { Visual } from './Visual';

export const CategoryManagementVisuals = () => {
    return (
        <Box
            position="relative"
            height="100%"
            minHeight="400px"
            backgroundImage={`url(${backgroundUrl})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
        >
            <Visual
                translate="0%"
                offset="10px"
                width={{
                    xs: '320px',
                    sm: '380px'
                }}
                style={{
                    bottom: '50%',
                    right: 0
                }}
            >
                <BenefitVisual name="category-management-rob" />
            </Visual>
            <Visual
                translate="50%"
                offset="20px"
                width={{
                    xs: '390px',
                    sm: '410px'
                }}
                style={{
                    left: 0,
                    bottom: '40%'
                }}
            >
                <BenefitVisual name="category-management-contracts" />
            </Visual>
        </Box>
    );
};
