import React from 'react';
import styled from 'styled-components';
import { Desktop, NonDesktop } from '../common';
import { Box } from '../system';
import ScreenA from './Screens/screen_a.png';
import ScreenLarge from './Screens/screen_large.png';

const translateWithOffset = ({ translate: [x, y], offset }) =>
    `transform: translate(${x}%, calc(${y}% + ${offset || '0px'}));`;

const Container = styled(Box)`
    position: relative;
`;

const Screen = styled.img`
    position: absolute;
    left: 50%;
    bottom: 0;
    width: calc(100vw - ${({ translate: [x] }) => (100 - Math.abs(x)) / 2}%);
    max-height: 100%;
    object-fit: contain;

    ${translateWithOffset}
`;

export const IntroScreens = props => {
    return (
        <Container height={['300px', '400px', '603px']} {...props}>
            <Desktop>
                <Screen
                    src={ScreenA}
                    alt=""
                    translate={[-36, -0]}
                    style={{
                        zIndex: 1
                    }}
                />

                <Screen
                    src={ScreenLarge}
                    alt=""
                    translate={[-59, 0]}
                    offset="80px"
                    style={{
                        zIndex: 1
                    }}
                />
            </Desktop>

            <NonDesktop>
                <Screen
                    src={ScreenA}
                    alt=""
                    translate={[-45, 0]}
                    style={{
                        width: '90vw',
                        maxWidth: '750px',
                        zIndex: 1,
                        opacity: 0.5
                    }}
                />
                <Screen
                    src={ScreenLarge}
                    alt=""
                    translate={[-50, 0]}
                    offset="30px"
                    style={{
                        width: '90vw',
                        maxWidth: '750px',
                        zIndex: 1
                    }}
                />
            </NonDesktop>
        </Container>
    );
};
