import React from 'react';
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

import {
    SEO,
    Layout,
    ProminentLeadForm,
    ContentSection,
    FeaturedPosts,
    SocialMediaBox,
    ClientOnly
} from '../components/common';

import { Header } from '../components/home-page/Header';
import { Partners } from '../components/home-page/Partners';
import { Testimonial } from '../components/home-page/Testimonial';
import { Features } from '../components/home-page/Features';
import { WhatOthersSay } from '../components/home-page/WhatOthersSay';
import { Box } from '../components/system';
import { BackgroundAnimation } from '../components/home-page/BackgroundAnimation';
import { Video } from '../components/home-page/Video';
import { IntroScreens } from '../components/home-page/IntroScreens';

const IndexPage = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SEO {...t('index.seo', { returnObjects: true })} />
            <ClientOnly>
                <Layout>
                    <Box paddingTop={[4, 0]} position="relative">
                        <Header />
                        <BackgroundAnimation />
                    </Box>

                    <Box marginY={[0, 4, 8]}>
                        <IntroScreens />
                    </Box>

                    <Box marginY={16}>
                        <Partners />
                    </Box>

                    <Box
                        paddingY={11}
                        marginY={16}
                        backgroundColor="bold-blueish.10"
                    >
                        <Testimonial
                            textKey={'index.header.quote'}
                            captionKey={'index.header.quoteCaption'}
                        />
                    </Box>

                    <Box marginY={16}>
                        <Video />
                    </Box>

                    <Box marginY={16}>
                        <Features />
                    </Box>

                    <Box
                        marginY={16}
                        paddingY={11}
                        backgroundColor="bold-blueish.10"
                    >
                        <Testimonial
                            textKey={'index.featuredTestimonial.quote'}
                            captionKey={'index.featuredTestimonial.subHeadline'}
                        />
                    </Box>

                    <Box marginY={11}>
                        <WhatOthersSay />
                    </Box>

                    <Box marginTop={{ xs: 14, md: 18 }}>
                        <FeaturedPosts postsPerRow={3} />
                    </Box>

                    <ContentSection>
                        <ProminentLeadForm
                            imagePair={{
                                firstUserId: 'jan-schwartau',
                                secondUserId: 'philippe-lavarde'
                            }}
                        />
                    </ContentSection>

                    <ContentSection>
                        <Container>
                            <SocialMediaBox />
                        </Container>
                    </ContentSection>
                </Layout>
            </ClientOnly>
        </React.Fragment>
    );
};

export default IndexPage;
